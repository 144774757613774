//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BannerImgLeft",
  props: ["blok"],
};
